import classNames from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';
import { IconSpinner } from '../../components';
import css from './FieldFileUpload.module.css';
import { IoMdCloudUpload } from 'react-icons/io';
const FileExtensionsMap = new Map([
  ['image/jpeg', 'jpeg'],
  ['image/jpg', 'jpg'],
  ['image/png', 'png'],
  ['application/pdf', 'pdf'],
  ['application/msword', 'doc'],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'docx'],
]);

function FieldFileUpload(props) {
  const {
    name,
    ACCEPT_FILES,
    onSelectFile,
    labelClassName,
    rootClassName,
    label,
    disabled,
    disabledLabelClassName,
    disabledRootClassName,
    validate,
    previewClassName,
    previewLabelClassName,
    disabledPreviewLabelClassName,
    loading,
    subLabelClass,
    ...restProps
  } = props;

  const classNameForLabel = disabled
    ? classNames(labelClassName, disabledLabelClassName)
    : labelClassName;

  const classNameForRoot = disabled
    ? classNames(rootClassName, disabledRootClassName)
    : rootClassName;

  const classNameForPreview = disabled
    ? classNames(previewLabelClassName, disabledPreviewLabelClassName)
    : previewLabelClassName;

  const classNameForImagePreview = loading ? css.loading : previewClassName;

  // const subLabel = ACCEPT_FILES.split(',')
  //   .map(contentType => '.' + FileExtensionsMap.get(contentType).toUpperCase())
  //   .join(', ');

  return (
    <div className={classNameForRoot}>
      {/* <label htmlFor={name} className={classNameForLabel}>
        {label}
      </label> */}
      <Field name={name} accept={ACCEPT_FILES} validate={validate}>
        {({ input: { value, onChange, ...rest } }) => {
          const handleChange = event => {
            const file = event.target.files[0];
            onChange(file);
            if (onSelectFile) onSelectFile(file);
          };

          const previewLink = value ? URL.createObjectURL(value) : null;

          const previewOrLabel = previewLink ? (
            <label htmlFor={name} className={classNameForPreview}>
              {loading && (
                <div className={css.spinner}>
                  <IconSpinner />
                </div>
              )}
              <img src={previewLink} className={classNameForImagePreview} />
            </label>
          ) : (
            <label htmlFor={name} className={classNameForLabel}>
              <IoMdCloudUpload className={css.uploadIcon} />
              <span>+ {label}...</span>
              {/* <small className={subLabelClass}>{subLabel}</small> */}
            </label>
          );

          return (
            <>
              {previewOrLabel}
              <input
                {...rest}
                type="file"
                onChange={handleChange}
                accept={ACCEPT_FILES}
                {...restProps}
                style={{ display: 'none' }}
              />
            </>
          );
        }}
      </Field>
    </div>
  );
}

FieldFileUpload.defaultProps = {
  ACCEPT_FILES: 'image/jpg,image/jpeg,image/png',
  onSelectFile: null,
  name: null,
  label: null,
  rootClassName: css.root,
  disabledRootClassName: css.disabledClassName,
  disabledLabelClassName: css.disabledUploadButton,
  labelClassName: css.uploadButton,
  previewClassName: css.previewClassName,
  previewLabelClassName: css.previewLabelClassName,
  disabledPreviewLabelClassName: css.disabledPreviewLabelClassName,
  subLabelClass: css.subLabel,
  loading: null,
};

export default FieldFileUpload;
